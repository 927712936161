<template>
  <div>
    <v-sheet class="pa-4 mb-3">
      <div>
        <v-row>
          <v-col v-if="roleStats" md="7">
            <h4>Распределение баллов по ролям</h4>
            <p>
              На текущий момент, согласно
              <a href="#" @click.prevent="$emit('openTresholds')"
                >пороговым значениям</a
              >
              компетенции и данным
              <router-link :to="{ name: 'competence_sync' }"
                >последнего пересчета</router-link
              >, распределение баллов конкурса талант среди
              <strong>{{ totalParticipants }}</strong> участника(ов) c
              ненулевыми баллами по ролям достижений выглядит следующим образом:
            </p>

            <ul>
              <li v-for="item in roleStats" :key="item.title">
                Достижение <strong>{{ item.title }}</strong> получат
                {{ item.users }} ({{ item.percentage }}%) пользователей,
                набравших более {{ item.lower_bound }} балла(ов)
              </li>
            </ul>
          </v-col>
          <v-col md="5">
            <h4>Статистика достижений</h4>
            <v-simple-table class="mb-3">
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">Статус</th>
                    <th class="text-right">Количество достижений</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in statsList" :key="item.title">
                    <td>{{ item.title }}</td>
                    <td class="text-right">{{ item.count }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <v-tabs background-color="white">
      <v-tab :to="{ name: 'competence_scores' }">Баллы участников</v-tab>
      <v-tab :to="{ name: 'competence_scores_chart' }">
        График распределения
      </v-tab>
    </v-tabs>
    <router-view
      :thresholds="thresholds"
      :competence="competence"
      @onUpdateTracks="$emit('onUpdateTracks')"
      @openTresholds="$emit('openTresholds')"
      @onOpenAssignDialog="$emit('onOpenAssignDialog')"
    ></router-view>
  </div>
</template>

<script>
import { apiClient } from "@/api";
import { STATUS_TEXTS } from "@/services/achievements";
export default {
  name: "CompetenceScorePage",
  props: {
    competence: {
      type: Object,
      required: true,
    },
    thresholds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      pending: false,
      scores: [],
      achievementStats: {},
    };
  },
  computed: {
    id() {
      return this.competence?.id;
    },
    roles() {
      return this.$store.state.talent.achievementRoles?.reduce((acc, v) => {
        acc[v.id] = v;
        return acc;
      }, {});
    },
    roleStats() {
      const { totalParticipants } = this;
      if (!this.thresholds?.length || !totalParticipants) {
        return;
      }
      // Сортируем пороги по возрастанию
      return [...this.thresholds]
        .sort((a, b) => {
          return a.lower_bound - b.lower_bound;
        })
        .reduce((acc, value, idx, arr) => {
          let min = value.lower_bound;
          const count = this.scores
            .filter((score) => {
              if (idx === arr.length - 1) {
                // если это максимальный порог
                return score.score >= min;
              }
              return (
                score.score >= min && score.score < arr[idx + 1]?.lower_bound
              );
            })
            .reduce((res, s) => {
              return res + s.count;
            }, 0);
          acc.push({
            title: this.roles[value.role_id]?.name || value.role_id,
            total: totalParticipants,
            users: count,
            lower_bound: min,
            percentage: ((count / totalParticipants) * 100).toFixed(2),
          });
          return acc;
        }, []);
    },
    totalParticipants() {
      if (!this.scores?.length) {
        return 0;
      }
      return this.scores.reduce((acc, value) => {
        return acc + value.count;
      }, 0);
    },
    statsList() {
      const { achievementStats } = this;
      return Object.entries(STATUS_TEXTS).reduce((acc, [status, title]) => {
        acc.push({
          title,
          status,
          count: achievementStats?.count?.[status] || 0,
        });
        return acc;
      }, []);
    },
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async init() {
      this.pending = true;
      try {
        await Promise.all([this.getScores(), this.getAchievementStats()]);
      } catch (error) {
        console.log("error.message", error);
      }
      this.pending = false;
    },
    async getAchievementStats() {
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/achievements/stats",
          params: {
            competence_id: this.id,
          },
        });
        if (Array.isArray(data)) {
          this.achievementStats = data[0];
        } else {
          this.achievementStats = {};
        }
      } catch (error) {
        this.$toast(
          `Не удалось получить информацию по статистике достижений. ${error.message}`,
          {
            type: "error",
          }
        );
        this.achievementStats = {};
      }
    },
    async getScores() {
      try {
        const { data } = await apiClient({
          method: "GET",
          url: "/scores/distribution",
          params: {
            competences_ids: this.id,
            years: new Date().getFullYear(),
            hide_zeros: true,
          },
        });
        this.scores = data;
      } catch (error) {
        this.$toast(
          `Не удалось получить информацию по распределению баллов в компетенции. ${error.message}`,
          {
            type: "error",
          }
        );
        this.scores = [];
      }
    },
  },
};
</script>

<style></style>
